<script>
import { VueDraggableNext } from 'vue-draggable-next'
import Swal from "sweetalert2";
import Multiselect from "@vueform/multiselect";
import { DateTime } from "luxon";
import "@vueform/multiselect/themes/default.css";
import { overlay } from "@/state/helpers";
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../../app.config";
import EmptyTableResult from "@/components/empty-table-result";
import modalDeal from "@/views/apps/crm/modal-deal";
import {getEntity, callUrl, deleteEntity, getUserData} from '@/helpers/api-apolo';
import {formatPrice, nFormatter, saError, saSuccess, getTimezone, sumBusinessProductPrices} from '@/helpers/global-functions';

export default {
  page: {
    title: "Negócios",
    meta: [{
      name: "description",
      content: appConfig.description
    }],
  },
  components: {
    modalDeal,
    Layout,
    PageHeader,
    Multiselect,
    draggable: VueDraggableNext,
    EmptyTableResult
  },
  data() {
    return {
      title: "Negócios",
      items: [{
        text: "CRM",
        href: "/",
      },
        {
          text: "Negócios",
          active: true,
        },
      ],
      isLoading: false,
      loadingBar: 0,
      showModalAddDealsFlag: false,
      users: [],
      userIsMaster: false,
      orderByBusiness: 'updated_at:0',
      pipelinesData: [],
      pipeline_phases: [],
      dealsData: [],
      selectPipeline: '',
      pipeline: {},
      stages: [],
      stagesColor: ['bg-success'],
      enabled: true,
      dragging: false,
      changed: {},
      reasons: [
        {value: 'not_responding', label: 'Não respondeu mais'},
        {value: 'will_postpone_the_process', label: 'Vai adiar o processo'},
        {value: 'canceled_the_process', label: 'Desistiu do processo'},
        {value: 'values_higher_than_expected', label: 'Valores acima do esperado'},
        {value: 'bought_with_the_competition', label: 'Fechou com a concorrência'},
        {value: 'dissatisfaction_with_the_service', label: 'Insatisfação com o serviço'},
      ],
      query: ''
      // stagesColor: ['bg-soft-danger','bg-soft-success','bg-soft-warning','bg-soft-info','bg-soft-secondary','bg-soft-light','bg-soft-primary','bg-soft-dark']
    };
  },
  computed: {
    messagesToAlertAboutInconsistencies() {
      let messages = [];
      if (this.userIsMaster) {
        if (!this.pipeline && !this.pipeline.attendants && !this.pipeline.attendants.length) {
          messages.push('Não existem atendentes atribuídos ao pipeline para que possam ser delegados os negócios.');
        }
        if (this.pipeline.must_have_product_to_give_gain && !this.pipeline.products.length) {
          messages.push('Pipeline configurado para ganho apenas de negócios com produtos, porém nenhum produto configurado.');
        }
      }
      return messages.join("<br><br>");
    }
  },
  methods: {
    ...overlay,
    camelize(str) {
      return str.replace(/^\w|[A-Z]|\b\w/g, function(word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      }).replace(/\s+/g, '');
    },
    formatStages(data) {
      let stages = [];
      let count = 0;
      for (let i = 0; i < data.length; i++) {
        let obj = data[i];
        if (!this.stagesColor[count]) {
          count = 0;
        }
        obj.bg = this.stagesColor[count];
        obj.name_label = this.camelize(obj.name).toLowerCase()
        obj.dealsData = [];
        count++;
        stages.push(obj);
      }
      return stages;
    },
    async getAllPipelines() {
      try {
        this.pipelinesData = [];
        let pipelineFiltered = sessionStorage.getItem('pipelineFiltered');
        const data = await getEntity('pipeline');
        if (data.length) {
          let defaultPipeline = data.findIndex(x => x.is_default);
          for (let i = 0; i < data.length; i++) {
            let obj = data[i];
            obj.value = data[i].id;
            obj.label = data[i].name;
            this.pipelinesData.push(obj);
          }
          if (pipelineFiltered) {
            defaultPipeline = this.pipelinesData.findIndex(x => x.value === pipelineFiltered);
          }
          this.selectPipeline = this.pipelinesData[defaultPipeline].value;
          this.pipeline = this.pipelinesData[defaultPipeline]
          this.stages = JSON.parse(JSON.stringify(this.formatStages(this.pipeline.phases)));
        }
        return true;
      } catch (error) {
        return false;
      }
    },
    async getUsers() {
      try {
        getEntity('user')
          .then(async (data) => {
            this.users = [];
            if (data?.length > 0) {
              for (let i = 0; i < data.length; i++) {
                let obj = data[i];
                if (!obj.avatar) {
                  obj.avatar = require('@/assets/images/users/user-dummy-img.jpg');
                }
                this.users.push(obj);
              }
            }
          });
      } catch (error) {
        saError(error.message);
      }
    },
    async getAllDeals(reset = false) {
      try {
        let url = '/pipeline/' + this.selectPipeline + '/business';
        if (this.query !== '') {
          url += '?query=' + encodeURI(this.query)
          url += '&order=' + this.orderByBusiness;
        } else {
          url += '?order=' + this.orderByBusiness;
        }
        let dataM = await callUrl({}, url, 'get');
        if (!dataM) {
          dataM = [];
        }
        if (reset) {
          this.stages = JSON.parse(JSON.stringify(this.formatStages(this.pipeline.phases)));
        }
        const now = DateTime.now().setZone(getTimezone());
        for (let i = 0; i < dataM.length; i++) {
          let obj = dataM[i];
          if (obj.status === 'Fechado') {
            continue;
          }
          obj.vip = false;
          if (dataM[i].vip) {
            obj.vip = dataM[i].vip
          }
          let value = 0;
          for (let j = 0; j < dataM[i].products.length; j++) {
            value = parseInt(value + dataM[i].products[j].price * dataM[i].products[j].quantity);
            if (dataM[i].products[j].discount > 0) {
              value = parseInt(value - (value * dataM[i].products[j].discount / 100));
            }
          }
          obj.value = value;
          dataM[i].value = value;
          if (!dataM[i].actions) {
            dataM[i].actions = [];
          }
          if (!dataM[i].description) {
            dataM[i].description = "";
          }
          dataM[i].person_name = dataM[i].person.name;
          let diffDealPhaseMinutes = 0;
          const deal_pipeline_phase_id = obj.pipeline_phases[0].pipeline_phase_id;
          for (let j = 0; j < obj.pipeline_phases.length; j++) {
            if (obj.pipeline_phases[j].pipeline_phase_id === deal_pipeline_phase_id) {
              let exited_at = now;
              if (obj.pipeline_phases[j].exited_at && obj.pipeline_phases[j].exited_at !== '') {
                exited_at = DateTime.fromISO(obj.pipeline_phases[j].exited_at).setZone(getTimezone());
              }
              let begun_at = DateTime.fromISO(obj.pipeline_phases[j].begun_at).setZone(getTimezone());
              diffDealPhaseMinutes = diffDealPhaseMinutes + exited_at.diff(begun_at, 'minutes').minutes;
            }
          }
          obj.updated = DateTime.fromISO(obj.updated_at).setZone(getTimezone()).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
          const minutes = parseInt(diffDealPhaseMinutes)
          let hours = 0;
          if (minutes > 60) {
            hours = parseInt(minutes / 60);
          }
          let days = 0;
          if (hours > 24) {
            days = parseInt(hours / 24);
          }
          let months = 0;
          if (days > 0 && days > 30) {
            months = parseInt(days / 30);
          }
          let years = 0;
          if (months > 0 && months > 12) {
            years = parseInt(months / 12);
          }
          obj.date_diff_value = 0;
          obj.date_diff_type = 'Minuto';
          obj.date_diff_flag = '';
          if (minutes > 0 && hours === 0) {
            obj.date_diff_value = minutes;
            if (minutes === 1) {
              obj.date_diff_type = 'Minuto';
            } else {
              obj.date_diff_type = 'Minutos';
            }
            obj.date_diff_flag = 'badge-soft-success';
          } else if (hours > 0 && days === 0) {
            obj.date_diff_value = hours;
            if (hours === 1) {
              obj.date_diff_type = 'Hora';
            } else {
              obj.date_diff_type = 'Horas';
            }
            obj.date_diff_flag = 'badge-soft-success';
          } else if (days > 0 && months === 0) {
            obj.date_diff_value = days;
            if (days === 1) {
              obj.date_diff_type = 'Dia';
            } else {
              obj.date_diff_type = 'Dias';
            }
            obj.date_diff_flag = 'badge-soft-success';
          } else if (months > 0 && years === 0) {
            if (months > 0 && months <= 3) {
              obj.date_diff_flag = 'badge-soft-success'
              obj.date_diff_value = months;
              if (months === 1) {
                obj.date_diff_type = 'Mês';
              } else {
                obj.date_diff_type = 'Mêses';
              }
            } else if (months > 3 && months <= 6) {
              obj.date_diff_flag = 'badge-soft-warning'
              obj.date_diff_value = months;
              obj.date_diff_type = 'Mêses';
            } else if (months > 6) {
              obj.date_diff_flag = 'badge-soft-danger'
              obj.date_diff_value = months;
              obj.date_diff_type = 'Mêses';
            }
          } else if (years > 0) {
            obj.date_diff_flag = 'badge-soft-danger';
            obj.date_diff_value = years;
            if (years === 1) {
              obj.date_diff_type = 'Ano';
            } else {
              obj.date_diff_type = 'Anos';
            }
          }
          obj.features = [];
          for (let j = 0; j < obj.products.length; j++) {
            obj.features.push(obj.products[j].name)
          }
          obj.users = [];
          if (obj.attendants && obj.attendants[0]) {
            for (let i = 0; i < this.users.length; i++) {
              if (obj.attendants[0].user_id === this.users[i].id) {
                obj.users.push(this.users[i])
                break;
              }
            }
          }
          obj.value_formatted = formatPrice(sumBusinessProductPrices(obj.products));
          obj.total_messages = 0;
          let sla_in_days = 0;
          obj.isOutDate = '';
          for (let x = 0; x < this.stages.length; x++) {
            if (this.stages[x].id === deal_pipeline_phase_id) {
              sla_in_days = this.stages[x].sla_in_days;
              if (days < (sla_in_days * 0.5)) {
                obj.isOutDate = 'deal-green-date';
              } else if (days >= (sla_in_days * 0.5) && days <= (sla_in_days * 0.99)) {
                obj.isOutDate = 'deal-warning-date';
              } else {
                obj.isOutDate = 'deal-danger-date';
              }
              if (!this.stages[x].dealsData) {
                this.stages[x].dealsData = [];
              }
              let found = false;
              this.stages[x].count = 0;
              this.stages[x].total = 0;
              this.stages[x].total_formatted = 0;
              for (let j = 0; j < this.stages[x].dealsData.length; j++) {
                if (obj.id === this.stages[x].dealsData[j].id) {
                  this.stages[x].dealsData[j] = obj;
                  found = true;
                }
              }
              if (!found) {
                this.stages[x].dealsData.push(obj)
              }
              this.stages[x].count++;
              this.stages[x].total = this.stages[x].total + obj.value;
              this.stages[x].total_formatted = nFormatter(this.stages[x].total, 1, 'R$')
            }
          }
        }
        this.updateStageTotal()
      } catch (error) {
        console.log(error.message);
      }
    },
    updateStageTotal() {
      for (let i = 0; i < this.stages.length; i++) {
        this.stages[i].count = 0;
        this.stages[i].total = 0;
        this.stages[i].total_formatted = 0;
        for (let j = 0; j < this.stages[i].dealsData.length; j++) {
          this.stages[i].count++;
          this.stages[i].total = this.stages[i].total + this.stages[i].dealsData[j].value;
          this.stages[i].total_formatted = nFormatter(this.stages[i].total, 1, 'R$')
        }
      }
    },
    showModalAddDeals() {
      this.showModalAddDealsFlag = true;
    },
    savedBusiness(pipelineId) {
      this.selectPipeline = pipelineId;
    },
    async sendChangePhase(url, method) {
      this.simulateAPICall();
      try {
        await callUrl({}, url, method)
          .then(async () => {
            this.cancelSimulateAPICall();
            await this.getAllDealsInterval(true)
          });
      } catch (error) {
        this.cancelSimulateAPICall();
        console.log(error.message)
        await this.getAllDealsInterval()
      }
    },
    async viewDeal(id) {
      await this.$router.push({path: '/apps/deal-details/' + id});
    },
    async setGain(deal) {
      Swal.fire({
        title: "Você tem certeza?",
        html: "Dar ganho para o negócio <b>" + deal.title + "</b>????",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: "SIM 🎉🎉🎉",
        cancelButtonText: "Cancelar",
        buttonsStyling: false,
        customClass: {
          actions: 'd-flex justify-content-center',
          confirmButton: 'btn btn-success flex-grow-1',
          cancelButton: 'btn btn-outline-dark flex-grow-1'
        }
      }).then(async (result) => {
        if (result.value) {
          try {
            this.changeShowOverlay({show: true});
            await callUrl({}, '/business/' + deal.id + '/done', 'post')
              .then(async () => {
                this.changeShowOverlay({show: false});
                saSuccess("🎉🎉🎉 Parabéns pelo ganho 🎉🎉🎉!")
                await this.getAllDealsInterval(true)
              });
          } catch (error) {
            this.changeShowOverlay({show: false});
            saError(error.message)
            console.log(error.message)
            await this.getAllDealsInterval()
          }
        }
      });
    },
    prepareLost(deal) {
      this.changed = deal
    },
    closeModal() {
      this.showModalAddDealsFlag = false;
    },
    cancelLost() {
      this.changed = {}
    },
    async setLost() {
      try {
        this.changeShowOverlay({show: true});
        await callUrl({reason_status: this.changed.reason_status, reason_observation: this.changed.reason_observation}, '/business/' + this.changed.id + '/lossed', 'post')
          .then(async () => {
            this.changeShowOverlay({show: false});
            saSuccess("Negócio perdido com sucesso 😭😭😭")
            await this.getAllDealsInterval(true)
            this.cancelLost()
          });
      } catch (error) {
        this.changeShowOverlay({show: false});
        saError(error.message)
        console.log(error.message)
        await this.getAllDealsInterval()
        this.cancelLost()
      }
    },
    async removeDeal(deal) {
      Swal.fire({
        title: "Você tem certeza?",
        html: "Será removido o negócio <b>" + deal.title + "</b><br>Essa é uma operação irreversível!!!",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: "SIM, pode remover️️",
        cancelButtonText: "Cancelar",
        buttonsStyling: false,
        customClass: {
          actions: 'd-flex justify-content-center',
          confirmButton: 'btn btn-success flex-grow-1',
          cancelButton: 'btn btn-outline-dark flex-grow-1'
        }
      }).then(async (result) => {
        if (result.value) {
          try {
            this.changeShowOverlay({show: true});
            deleteEntity(deal.id, 'business')
              .then(async () => {
                this.changeShowOverlay({show: false});
                Swal.fire("Sucesso!", "Removido com sucesso", "success");
                await this.getAllDealsInterval(true)
              }).catch((er) => {
              console.log(er)
            });
          } catch (error) {
            this.changeShowOverlay({show: false});
            saError(error.message)
            console.log(error.message)
            await this.getAllDealsInterval(true)
          }
        }
      });
    },
    reset () {
      this.query = ''
      this.getAllDealsInterval(true)
    },
    simulateAPICall() {
      this.isLoading = true;
      let currentProgress = 0;
      let progress = 0;
      let step = 0.5;

      this.interval = setInterval(() => {
        currentProgress += step;
        progress = Math.round(Math.atan(currentProgress) / (Math.PI / 2) * 100 * 1000) / 1000;
        this.loadingBar = progress;

        if (progress >= 100) {
          this.cancelSimulateAPICall();
        } else if (progress >= 70) {
          step = 0.1;
        }
      }, 100); // 100ms = 0.1 seconds
    },
    cancelSimulateAPICall() {
      clearInterval(this.interval);
      this.loadingBar = 100;
      setTimeout(() => {
        this.loadingBar = 0;
        this.isLoading = false;
      }, 500);
    },
    changeStatus (data) {
      if (data.added) {
        this.changed = data.added.element;
      }
    },
    async changePhase(phase_id) {
      let url = '/business/' + this.changed.id + '/pipeline-phase/' + phase_id;
      await this.sendChangePhase(url, 'post')
    },
    async getAllDealsInterval(reset = false) {
      if (reset) {
        clearInterval(this.intervalDeals);
        await this.getAllDeals(true)
        this.intervalDeals = setInterval(async () => {
          await this.getAllDeals()
        }, 10000);
      } else {
        await this.getAllDeals()
      }
    }
  },
  beforeUnmount () {
    clearInterval(this.intervalDeals);
    clearInterval(this.interval);
  },
  async mounted() {
    this.changeShowOverlay({show: true});
    await this.getAllPipelines();
    if (this.pipelinesData.length > 0) {
      await this.getUsers();
    }
    this.changeShowOverlay({show: false});
    const userData = getUserData();
    this.userIsMaster = (userData.profile === 'master' || userData.profile === 'coordenador');
  },
  watch: {
    orderByBusiness() {
      this.getAllDealsInterval(true)
    },
    async query(value) {
      if (value === '' || value.length === 0) {
        await this.getAllDealsInterval(true)
      }
    },
    async selectPipeline(value) {
      if (value && value !== '') {
        this.simulateAPICall();
        for (let i = 0; i < this.pipelinesData.length; i++) {
          if (this.pipelinesData[i].id === value) {
            this.pipeline = this.pipelinesData[i];
            break;
          }
        }
        sessionStorage.setItem('pipelineFiltered', value);
        this.stages = JSON.parse(JSON.stringify(this.formatStages(this.pipeline.phases)));
        await this.getAllDealsInterval(true)
        this.cancelSimulateAPICall();
      }
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="card">
      <div class="card-body">
        <div class="row g-3">
          <div class="col-lg-auto">
            <button class="btn btn-success" @click="showModalAddDeals"><i class="ri-add-fill align-bottom me-1"></i> Negócio</button>
          </div>
          <div class="col-md-3  col-auto">
            <div class="search-box">
              <input type="text" class="form-control search" placeholder="Pesquisar..." v-model="query" @keydown.enter.prevent="getAllDealsInterval(true)">
              <i class="ri-search-line search-icon"></i>
              <i class=" ri-close-line search-icon" style="right: 7px !important; left: unset !important; cursor: pointer !important;" v-show="query.length > 0" @click="reset()"></i>
            </div>
            <div class="form-text" v-show="query.length > 0">Aperte ENTER para buscar.</div>
          </div>
          <div class="col-md-4">
            <div class="d-flex hastck gap-2 flex-wrap">
              <div class="d-flex align-items-center gap-2" style="width: -webkit-fill-available !important">
                <Multiselect class="form-control" v-model="selectPipeline" :close-on-select="true" :searchable="true" :create-option="true" :options="pipelinesData" />
              </div>
            </div>
          </div>
          <div class="col-md-auto ms-auto">
            <div class="d-flex hastck gap-2 flex-wrap">
              <div class="d-flex align-items-center gap-2">
                <Multiselect class="form-control" style="width:200px;" v-model="orderByBusiness" :close-on-select="true" :searchable="true" :create-option="true" :options="[
                                  { value: 'created_at:1', label: 'Criado Em' },
                                  // { value: 'created_at:1', label: 'Criado Em (Asc)' },
                                  { value: 'updated_at:0', label: 'Atualizado Em' },
                                  // { value: 'updated_at:1', label: 'Atualizado Em (Asc)' },
                                  // { value: 'deal_value', label: 'Valor de Negócio' },
                                  ]" />
              </div>
            </div>
          </div>
          <div class="col-md-auto fs-3" v-if="messagesToAlertAboutInconsistencies.length > 0">
            <i class="mdi mdi-alert-circle text-danger" v-b-tooltip.hover :title="messagesToAlertAboutInconsistencies" html="true" customClass="text-start"></i>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <b-progress v-show="isLoading" :value="loadingBar" class="bg-gradient progress-sm mb-0" variant="primary"></b-progress>
    </div>
    <!--end card-->

    <EmptyTableResult
      v-show="pipelinesData.length === 0"
      text="Verifique com seu gestor se você esta associado à algum pipeline" />

    <div v-show="pipelinesData.length > 0" class="tasks-board mb-3" id="kanbanboard" style="height: 100% !important; min-height: 250px !important; margin-bottom: 30px !important;overflow-y: hidden;">
      <div class="tasks-list" style="position: relative !important;" v-for="(stage, key) in stages" :key="'stage-' + key">
        <div :class="['d-flex','mb-3']"><!-- stage.bg -->
          <div class="flex-grow-1">
            <h6 class="fs-14 text-uppercase fw-semibold mb-0">{{stage.name}}</h6>
            <p class="text-muted mb-0">
              <span v-show="stage.total_formatted && stage.total_formatted !== ''" >{{stage.total_formatted}}</span>&nbsp;
              <span v-show="stage.total_formatted && stage.total_formatted !== ''" class="fw-medium">{{stage.count}} negócios</span>
            </p>

          </div>
        </div>
        <div data-simplebar class="tasks-wrapper px-3 mx-n3" style="max-height: 100% !important;height: 100% !important;">
          <div :id="'#'+stage.name_label" :class="[stage.dealsData.length === 0 ? 'dealsEmpty' : '']">
            <draggable :list="stage.dealsData" class="dragArea" style="height: 65vh !important;" :group="{ name: 'deals' }" @add="changePhase(stage.id)" @change="changeStatus">
              <div :class="['card', 'tasks-box', deal.isOutDate !== '' ? deal.isOutDate : '']" v-for="(deal, index) of stage.dealsData" :key="index">
                <div class="card-body pt-2">
                  <small :class="['badge', 'mb-2', deal.date_diff_flag]">{{deal.date_diff_value}} {{deal.date_diff_type}}</small>
                  <div class="d-flex">
                    <h6 class="fs-15 mb-0 flex-grow-1 text-truncate"><router-link :to="'/apps/deal-details/' + deal.id">{{deal.title}}</router-link></h6>
                    <div class="dropdown">
                      <a href="javascript:void(0);" class="text-muted" id="dropdownMenuLink1" data-bs-toggle="dropdown" aria-expanded="false"><i class="ri-more-fill"></i></a>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink1">
                        <li><a class="dropdown-item" href="#" @click.prevent="viewDeal(deal.id)"><i class="ri-edit-2-line align-bottom me-2 text-muted"></i> Editar</a></li>
                        <li><a class="dropdown-item" href="#" @click="setGain(deal)"><i class="ri-medal-line align-bottom me-2 text-muted" style="color: gold !important"></i> Dar Ganho</a></li>
                        <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#setLostModal" @click="prepareLost(deal)"><i class="ri-thumb-down-fill align-bottom me-2 text-muted" style="color: black !important"></i> Dar Perda</a></li>
                        <li><a class="dropdown-item" href="#" @click="removeDeal(deal)"><i class="ri-delete-bin-5-line align-bottom me-2 text-muted"></i> Remover</a></li>
                      </ul>
                    </div>
                  </div>
                  <p class="text-muted">{{deal.description}}</p>
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1" >
                      <span class="badge badge-soft-primary me-1" v-for="(fet, ind) of deal.features" :key="ind">{{ fet }}</span>
                    </div>
                    <div class="flex-shrink-0">
                      <div class="avatar-group">
                        <a href="javascript: void(0);" v-for="(user, index) of deal.users" :key="index" class="avatar-group-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" :title="user.name">
                          <img :src="user.avatar" alt="" class="rounded-circle avatar-xxs">
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer border-top-dashed">
                  <div class="d-flex">
                    <div class="flex-grow-1">
                      <h6 class="text-muted mb-0">
                        <router-link :to="{ name: 'people-edit', params: { id: deal.person.id } }" class="text-muted">{{deal.person_name}}</router-link> - <small>{{deal.value_formatted}}</small>
                      </h6>
                    </div>
                    <div class="flex-shrink-0">
                      <ul class="link-inline mb-0">
                        <li class="list-inline-item">
                          <router-link :to="{ name: 'chat-by-business', params: { pipelineId: deal.pipeline_id, businessId: deal.id } }" class="text-muted"><i class="ri-question-answer-line align-bottom"></i></router-link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div><!--end card-body-->
              </div><!--end card-->
            </draggable>
          </div><!--end tasks-->
        </div>
      </div><!--end tasks-list-->
    </div>
    <!-- Varying modal content -->
    <div class="modal zoomIn" id="setLostModal" tabindex="-1" aria-labelledby="setLostModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="setLostModalLabel">Você tem certeza?</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <h6>Dar perda para o negócio <b>{{changed.title}}</b>????</h6>
            <form>
              <div class="mb-3">
                <label for="recipient-name" class="col-form-label">Motivo:</label>
                <select class="form-select mb-3" v-model="changed.reason_status" aria-label="Selecione o Motivo">
                  <option v-for="(reason, key) in reasons" :key="'reason-' + key" :value="reason.label">{{reason.label}}</option>
                </select>
              </div>
              <div class="mb-3">
                <label for="message-text" class="col-form-label">Observação:</label>
                <textarea class="form-control" v-model="changed.reason_observation" id="message-text"></textarea>
              </div>
            </form>
          </div>
          <div class="modal-footer swal2-actions" style="margin-bottom: 15px !important;margin-top: 0 !important;">
            <button type="button" class="btn btn-outline-dark"  data-bs-dismiss="modal" @click="cancelLost">Close</button>
            <button type="button" class="btn btn-success" @click.prevent="setLost" data-bs-dismiss="modal">SIM 😱</button>
          </div>
        </div>
      </div>
    </div>
    <modal-deal
      :pipelinesData="pipelinesData"
      :pipelineData="pipeline"
      :showModal="showModalAddDealsFlag"
      title="Negócio"
      v-on:closeModal="closeModal"
      v-on:savedBusiness="savedBusiness"
    />
  </Layout>
</template>
<style scoped>
.deal-green-date {
  border-width: 3px;
  border-style: solid;
  border-image:
    linear-gradient(
      to bottom,
      #009246,
      rgba(0, 0, 0, 0)
    ) 1 100%;
}
.deal-danger-date {
  border-width: 3px;
  border-style: solid;
  border-image:
    linear-gradient(
      to bottom,
      red,
      rgba(0, 0, 0, 0)
    ) 1 100%;
}
.deal-warning-date {
  border-width: 3px;
  border-style: solid;
  border-image:
    linear-gradient(
      to bottom,
      gold,
      rgba(0, 0, 0, 0)
    ) 1 100%;
}
.dealsEmpty {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 180px;
  width: 270px;
  margin: 0 auto;
  background-image: url('~@/assets/images/file.png');
  background-size: cover;
  background-position: center;
  opacity: 0.4;
}
.vti__dropdown,.vti__selection{
  padding:0 !important;
}
</style>
